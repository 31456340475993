import React from "react"
import moment from "moment"
import ReactMarkdown from "react-markdown"
import "./style.scss"

class BlogLink extends React.Component {
  render() {
    const { title, published, url } = this.props.data.frontmatter
    const { domain } = this.props.data.fields
    const { html } = this.props.data

    return (
      <div className="post">
        <div className="post__meta">
          <time
            className="post__meta-time"
            dateTime={moment(published).format("MMMM D, YYYY")}
          >
            Shared on {moment(published).format("D MMMM YYYY")}
          </time>
          <span className="post__meta-domain"> &raquo; {domain}</span>
          <span className="post__meta-divider" />
        </div>
        <h3 className="post__sharetitle">
          <a className="post__sharetitle-link" href={url}>
            {title}
          </a>
        </h3>
        {html && (
          <div className="post__description">
            <div dangerouslySetInnerHTML={{ __html: html }} />
          </div>
        )}
      </div>
    )
  }
}

export default BlogLink
