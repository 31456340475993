import React from "react"
import { Link } from "gatsby"
import moment from "moment"
import ReactMarkdown from "react-markdown"
import "./style.scss"

class BlogPost extends React.Component {
  render() {
    const { title, published, url, summary } = this.props.data.frontmatter
    const { slug } = this.props.data.fields

    return (
      <div className="post">
        <div className="post__meta">
          <time
            className="post__meta-time"
            dateTime={moment(published).format("MMMM D, YYYY")}
          >
            Posted on {moment(published).format("D MMMM YYYY")}
          </time>
          <span className="post__meta-divider" />
        </div>
        <h2 className="post__title">
          <Link className="post__title-link" to={slug}>
            {title}
          </Link>
        </h2>
        {summary && (
          <div className="post__description">
            <ReactMarkdown source={summary} />
            {slug && !url && (
              <Link className="post__readmore" to={slug}>
                Read more
              </Link>
            )}
          </div>
        )}
      </div>
    )
  }
}

export default BlogPost
