import React from "react"
import BlogLink from "./BlogLink"
import BlogPost from "./BlogPost"
import "./style.scss"

class Post extends React.Component {
  render() {
    const { type } = this.props.data.frontmatter

    return (
      <div className="post">
        {type === "link" ? (
          <BlogLink {...this.props} />
        ) : (
          <BlogPost {...this.props} />
        )}
      </div>
    )
  }
}

export default Post
