import React from "react"
import { graphql } from "gatsby"
import Helmet from "react-helmet"

import Layout from "../components/Layout"
import Post from "../components/Post"
import Sidebar from "../components/Sidebar"

class IndexRoute extends React.Component {
  render() {
    const items = []
    const { title, subtitle } = this.props.data.site.siteMetadata
    const posts = this.props.data.posts.edges
    posts.forEach(post => {
      items.push(<Post data={post.node} key={post.node.id} />)
    })

    return (
      <Layout>
        <div>
          <Helmet>
            <title>{title}</title>
            <meta name="description" content={subtitle} />
          </Helmet>
          <Sidebar {...this.props} />
          <div className="content">
            <div className="content__inner">{items}</div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default IndexRoute

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        subtitle
        copyright
        menu {
          label
          path
        }
        author {
          name
          email
          twitter
          github
        }
      }
    }
    posts: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { type: { in: ["post", "link"] } } }
    ) {
      edges {
        node {
          html
          fields {
            slug
            domain
          }
          frontmatter {
            url
            type
            published: date(formatString: "MMMM DD, YYYY")
            title
            summary
          }
        }
      }
    }
  }
`
